import { defineComponent, onMounted, onBeforeUnmount, ref, watch, computed } from 'vue';

export default defineComponent({
  name: 'writepane',
  props: {
    active_dialog: String,
    new_contact_id: String,
    new_phone_number: String
  },
  template: '#writepane-template',
  setup(props, { emit }) {
    const messageTextInput = ref(null);
    const messageImprint = ref(null);

    // Computed property to access new_contact_id reactively
    const newContactId = computed(() => props.new_contact_id);
    
    // Watch for changes in new_contact_id
    watch(newContactId, (newId) => {
      console.log('New Contact ID:', newId); // Log the new contact ID
    }, { immediate: true });

    const sendButtonClicked = () => {
      if ($('#message_text_input')[0].value == '') {
        return false;
      }
      else {
        messageFormSubmit(); // Call submit method directly
      }
    };

    const random_id = () => {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    };

    const messageFormSubmit = async (event) => {
      const text = $('#message_text_input')[0].value;
      const imprint = random_id();
      messageImprint.value = imprint;

      const newContactId = document.querySelector('input[name="new_contact_id"]').value;
      const newPhoneNumber = document.querySelector('input[name="new_phone_number"]').value;

      // Ensure the message text is not empty
      if (!text) {
        console.log('Message input is empty, not submitting.');
        return;
      }

      // Prepare the data for submission
      const data = {
        message: {
          dialog_id: document.getElementById('message_dialog_id').value, // Get dialog_id from hidden input
          imprint: imprint,
          text: text
        },
        new_contact_id: newContactId, // Add new_contact_id to params
        new_phone_number: newPhoneNumber // Add new_phone_number to params
      };

      console.log('Submitting:', data);

      // AJAX submission
      try {
        const response = await fetch('/messages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify(data) // Send the prepared data
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        // Optionally, refresh the page
        window.location.reload();
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const messageFormBeforeSend = () => {
      messageTextInput.value.value = '';
    };

    onMounted(() => {
      const form = document.getElementById('message_form');
      if (form) {
        form.addEventListener('ajax:before', (event) => {
          console.log('Ajax before event triggered');
          messageFormSubmit(event);
        });
        form.addEventListener('ajax:beforeSend', () => {
          console.log('Ajax before send event triggered');
          messageFormBeforeSend();
        });
      }
    });


    onBeforeUnmount(() => {
      const form = document.getElementById('message_form');
      if (form) {
        form.removeEventListener('ajax:before', messageFormSubmit);
        form.removeEventListener('ajax:beforeSend', messageFormBeforeSend);
      }
    });

    // Return everything that needs to be accessed in the template
    return {
      messageTextInput,
      messageImprint,
      newContactId,
      sendButtonClicked // Make sure this method is returned so it can be used in the template
    };
  }
});
