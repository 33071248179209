import { defineComponent, ref, computed, watch } from 'vue';

export default defineComponent({
  name: 'Dlg',
  props: {
    dlg: {
      type: Object,
      required: true
    },
    active_dialog: {
      type: [String, Number],
      required: true
    }
  },
  setup(props, { emit }) {
    // Reactive reference for active class
    const active_class = ref(activeClass());

    // Computed properties
    const badge_seen = computed(() => props.dlg.unread_count > 0);
    const last_time = computed(() => {
      const msgs = props.dlg.messages;
      return msgs && msgs.length ? msgs[msgs.length - 1].human_time : null;
    });
    const last_text = computed(() => {
      const msgs = props.dlg.messages;
      return msgs && msgs.length ? msgs[msgs.length - 1].text : null;
    });

    // Methods
    function activate() {
      emit('update', props.dlg.id);
    }

    function activeClass() {
      return props.dlg.id === props.active_dialog ? 'active' : '';
    }

    function updateActiveClass() {
      active_class.value = activeClass();
    }

    // Watchers
    watch(() => props.active_dialog, updateActiveClass);

    return {
      badge_seen,
      last_time,
      last_text,
      active_class,
      activate,
    };
  },
  template: '#dialog-template'
});