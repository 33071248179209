$(function() {
  if (gon.faye_url) {
    var faye = new Faye.Client(gon.faye_url)

    var updateGlobalUnreadCounter = function(global_unread_count) {
      if (vm_messaging_counter) {
        vm_messaging_counter.unread = global_unread_count
      }
    }
  
    faye.subscribe('/messages/flag', function (data) {
      if (vm_messaging) {
        vm_messaging.removeDialogByPhoneNumber(data['phone'])
      }

      if (vm_messaging_counter) {
        vm_messaging_counter.requestUpdatedCount()
      }
    })    

    faye.subscribe('/messages/'+ messaging_account_channel + '/mark_read', function (data) {
      updateGlobalUnreadCounter(data['global_unread_count'])

      if (vm_messaging) {
        vm_messaging.markReadInUI(data['id'])
      }
    })

    faye.subscribe('/messages/'+ messaging_account_channel + '/dialog_remove', function (data) {
      if (vm_messaging) {
        vm_messaging.removeDialog(data['id'])
      }
    })

    faye.subscribe('/messages/'+ messaging_account_channel + '/message_remove', function (data) {
      if (vm_messaging) {
        vm_messaging.removeMessageArrived(data['msg']['message'])
      }
    })

    faye.subscribe('/messages/'+ messaging_account_channel + '/new', function (data) {
     updateGlobalUnreadCounter(data['global_unread_count']) 

      if (vm_messaging) {
        vm_messaging.messageReceived(data)
      }
    })
  }
})
