import { createApp } from 'vue'
import Dlg from './components/dialog';
import writepane from './components/writepane';
import message from './components/message';

document.addEventListener('DOMContentLoaded', function() { 
  if (document.getElementById("vm-messaging")) {
    
    const app = createApp({
      name: 'Messenger',
      data() {
        return {
          showDropdown: false,
          options: [],
          dialogs: gon.dialogs,
          initialContacts: [],
          contacts: [],
          messages: [],
          active_dialog_id: null,
          active_e164_phone: null,
          active_dialog_new: false,
          new_contact_selection: '',
          new_contact_id: null,
          new_phone_number: '',
          active_name: '',
          left_tab_class: 'active',
          right_tab_class: ''
        }
      },
      created() {
  
        this.contacts = this.parseContacts(gon.contacts);
        this.initialContacts = this.contacts;
        this.options = this.contacts; // Ensure options are set from contacts
        this.setInitialDialog();
        this.updateChat();
      },
      watch: {
        active_dialog_id: 'updateChat',
      },
      methods: {
        messageRemovedEvent(msg) {
          this.removeLocalMessage(msg)

          if (msg.id) {
            fetch('/messages/' + msg.id + '/trash', { method: 'POST' })
          }
        },
        // remove from pane, remove from dialogs pane
        removeMessageArrived(msg) {
          if (msg.id) {
            this.removeMessage(msg.id)
          }

          if (msg.dialog_id) {
            this.removeMessageFromDialog(msg.id, msg.dialog_id)
          }
        },
        //local removal: remove from pane (auto from dialogs pane) + check dialog removal
        removeLocalMessage(msg) {
          if (msg.id) {
            this.removeMessage(msg.id)
          }

          if (msg.dialog_id) {
            const d = this.findDialog(msg.dialog_id)

            if (d.messages.length == 0) {
              this.removeDialog(d.id)
            }
          }
        },
        scrollToDialog(id) {
          const list = document.getElementById("left-pane")
          const targetLi = document.getElementById('dialog-' + id)

          list.scrollTop = (targetLi.offsetTop - 218)
        },
        newDialogClicked() {
          this.showDropdown = true;
    
          if (!this.findDialog(-1)) {
            this.dialogs.unshift({id: -1, messages: [], new: true})
          }
          this.active_dialog_id = -1
        },
        flagCurrentDialogClicked() {
          if (confirm('Are you sure you want to flag? Contact and all messages will be deleted and phone number reported.')) {
            const id = this.active_dialog_id
            this.removeDialog(id)
            fetch('/dialogs/' + id + '/flag', { method: 'POST' })
          }
        },
        deleteCurrentDialogClicked() {
          if (confirm('Are you sure you want to delete the conversation?')) {
            const id = this.active_dialog_id
            this.removeDialog(id)
            fetch('/dialogs/' + id + '/trash', { method: 'POST' })
          }
        },

        parseContacts: function(contacts) {
          let parsed = []

          for (var k in contacts) {
            parsed.push({
              id: contacts[k].contact.id,
              name: contacts[k].contact.middle_name,
              phone: contacts[k].contact.phone,
              value: contacts[k].contact.id,
              label: contacts[k].contact.middle_name + ' (' + contacts[k].contact.phone + ')',
            })
          }

          return parsed
        },
        searchPerformed(search, loading) {
          loading(true)

          this.abortContactSearchIfRunning()

          window.contacts_search_request = fetch(`/search/im/contacts.json?term=${search}`)
            .then(response => response.json())
            .then(data => {
              this.contacts = this.parseContacts(data)
              loading(false)
            })
            .catch(() => {
              loading(false)
            })
        },
        abortContactSearchIfRunning() {
          if (window.contacts_search_request) {
            window.contacts_search_request.abort()
          }
        },
        setInitialDialog() {
          if (gon.initial) {
            this.active_dialog_id = gon.initial

            this.$nextTick(() => {
              this.scrollToDialog(gon.initial)
            })

            if (history.replaceState) {
              history.replaceState('', 'Messages', '/im')
            }
          }
        },
        updateChat() {
          const ad = this.activeDialog();
          
          if (ad) {
            this.toChatTab();
            this.active_dialog_new = ad.new;

            if (!this.active_dialog_new) {
              this.new_contact_id = null;
              this.new_phone_number = null;
            }

            this.markReadInUI(ad.id);
            this.messages = ad.messages;
            this.active_name = ad.name;
            this.active_e164_phone = ad.e164_phone;
            this.scrollChatToBottom();
          }

          // Remove contact selection from select
          if (this.new_contact_selection) {
            this.new_contact_selection = '';  // Clear the selection
          }
        },
        removeMessage(id) {
          const index = this.findMessageIndex(id)
          
          if (index != -1) {
            this.messages.splice(index, 1)
          }
        },
        removeMessageFromDialog(msg_id, dlg_id) {
          const d = this.findDialog(dlg_id)
          const index = this.findMessageIndexInDialog(msg_id, d)

          if (index != -1) {
            d.messages.splice(index, 1)
          }
        },
        removeDialog(id) {
          const index = this.findDialogIndex(id)
          
          if (index != -1) {
            this.dialogs.splice(index, 1)
          }

          if (this.active_dialog_id == id) {
            this.resetDialog()
          }
        },
        removeDialogByPhoneNumber(phone) {
          const d = this.findDialogByPhoneNumber(phone)
          if (d) {
            this.removeDialog(d.id)
          }
        },
        dialogUpdate(id) {
          this.active_dialog_id = id
        },
        findDialog(id) {
          return this.dialogs.find(d => d.id == id)
        },
        findDialogIndex(id) {
          return this.dialogs.findIndex(d => d.id == id)
        },
        //e164 numbers only
        findDialogByPhoneNumber(phone) {
          return this.dialogs.find(d => d.e164_phone == phone)
        },
        findDialogByPhoneDigits(phone_digits) {
          return this.dialogs.find(d => d.phone_digits == phone_digits)
        },
        findDialogByContactId(contact_id) {
          return this.dialogs.find(d => d.contact_id == contact_id)
        },
        findMessageIndex(id) {
          return this.messages.findIndex(m => m.id == id)
        },
        findMessageIndexInDialog(id, d) {
          return d.messages.findIndex(m => m.id == id)
        },
        activeDialog() {
          return this.findDialog(this.active_dialog_id)
        },
        markReadInUI(id) {
          const d = this.findDialog(id)
          
          if (d && d.unread_count > 0) {
            d.unread_count = 0
          }
        },
        markRead(id) {
          this.markReadInUI(id);
          
          if (id != 0) {
            if (!this.active_dialog_new) {
              fetch('/dialogs/' + id + '/mark_read', { method: 'POST' });
            }
          }
        },
        searchCleared() {
          this.contacts = this.initialContacts;
        },

        updateSelectedContact() {
          const selectedContactId = this.new_contact_selection;

          // Reset the contacts list and abort any ongoing search
          this.contacts = this.initialContacts;
          this.abortContactSearchIfRunning();

          // Check if a contact is selected
          if (selectedContactId) {
            const selectedContact = this.contacts.find(contact => contact.id === selectedContactId);

            // If the contact is found, proceed accordingly
            if (selectedContact) {
              const existingDialog = this.findDialogByContactId(selectedContact.id);
              
              if (existingDialog) {
                // Activate the existing dialog for this contact
                this.activateExistingDialogFromNewDialog(existingDialog);
              } else {
                // Set new contact information
                this.new_contact_id = selectedContact.id;
                this.new_phone_number = null;
              }
            }
          } else {
            // No contact is selected, reset the selection
            this.new_contact_id = null;
            this.new_phone_number = null;
          }
        },

        activateExistingDialogFromNewDialog(d) {
          this.active_dialog_id = d.label;
          this.updateChat();
          this.$nextTick(() => {
            this.scrollToDialog(d.id);
          })
        },
        scrollChatToBottom() {
          this.$nextTick(() => {
            const messagesContainer = document.getElementById('messages-container')
            if (messagesContainer) {
              messagesContainer.scrollTop = messagesContainer.scrollHeight
            }
          })
        },
        toDialogsClicked() {
          this.showDropdown = false;
          this.resetDialog()
          this.right_tab_class = ''
          this.left_tab_class = 'active'
        },
        resetDialog() {
          this.active_dialog_id = 0
          this.active_dialog_new = false
          this.messages = []
          this.active_name = ''
        },
        toChatTab() {
          this.left_tab_class = ''
          this.right_tab_class = 'active'
        }
      },
    })
    
    app.component('Dlg', Dlg);
    app.component('message', message);
    app.component('writepane', writepane);

    app.mount('#vm-messaging');
  }
})
