document.addEventListener('turbo:load', () => {
  $('form').on('click', 'a.add_child', function(event) {
    var assoc = $(this).data('association'),
      template_id = $(this).data('template-id') || (assoc + '_fields_template'),
      regexp = new RegExp('new_' + assoc, 'g'),
      new_id = new Date().getTime(),
      content = $('#' + template_id).html().replace(regexp, new_id),
      $content = $(content);

    $(this).before($content);

    $(this).trigger("motorlot:child-added", $content);

    return false;
  });

  var $el = $('#marketing-usage-graph');
  var data = [
    {
      label: 'Posts',
      data: $el.data('posts')
    },
    {
      label: 'Clicks',
      data: $el.data('clicks')
    },
    {
      label: 'Calls',
      data: $el.data('calls')
    },
    {
      label: 'Leads',
      data: $el.data('leads')
    },
    {
      label: 'Texts',
      data: $el.data('texts')
    }
  ];

  var options = {
    xaxis: {
      mode: 'time',
      timeformat: $el.data('time-format'),
      ticks: $el.data('ticks')
    }
  };

  $.plot($el, data, options);

  $(".deals .add-cobuyer .add_child").on("motorlot:child-added", function(event, el) {
    $(el).find("select.combobox").combobox();
    $(el).find(".contact-form :input").attr("disabled", true);
  });
  
  if ($('input[name="floorplan_type"]:checked').length === 0) {
    $('#active_checkbox').prop('checked', true);
  }

  $('#active_checkbox, #closed_checkbox').on('change', function() {
    if (this.checked) {
      $('#active_checkbox, #closed_checkbox').not(this).prop('checked', false);
    }
  });
  
  $("form.edit_deal, form.new_deal").on("click", ".edit-inventory-button", function(event) {
    var $button = $(this);
    var $form = $button.closest("form");
    var $inv = $form.find(".edit-inventory");
    
    if ($inv.is(":visible")) {
        $inv.find(':input').prop('disabled', true);
        $inv.hide();
        $button.html("View/edit vehicle");
    } else {
        var inventory_id = $form.find("[name='deal[vehicle_id]']").val();
        
        if (inventory_id) {
            $button.html("Loading...");
            $inv.find(':input').prop('disabled', false).val(null);
            
            $.get("/inventory/" + inventory_id + ".json", function(data) {
                $inv.find(":input").each(function(idx, el) {
                    var $input = $(el);
                    var name = $input.attr("name");
                    var match;
                    var match;
                    if (name && (match = name.match(/deal\[vehicle_attributes\]\[(.+)\]/))) {
                        var field = match[1];
                        $input.val(data.inventory[field]);
                    }
                });
                
                $inv.show();
                $inv.addClass('styled-inner-form');
                $button.html("Hide vehicle details");
            });
        }
    }
  });

  $(".deals").on("click", ".remove-cobuyer", function(event) {
    var $group = $(this).closest(".control-group");
    var name = $group.find("input[name^='deal[cobuyers_attributes]']").attr("name");
    $group.after("<input type='hidden' name='" + name + "'>");
    $group.remove();
    event.preventDefault();
    event.stopPropagation();
    return false;
  });

  $('.customer-form').find(':input').attr('disabled', true);
  $('.deals').on('click', '.add-contact', addContact);
  function addContact(event) {
    var $this = $(this),
      originalTitle = $this.attr('title'),
      f, nameField, namespace, vendorFields, vendorLink, addText, removeText, $cb_clear;
    f = $this.closest('.field').siblings('.contact-fields');

    addText = $this.data('add-text') || 'Add new contact';
    removeText = $this.data('remove-text') || 'Remove new contact';

    f.find('fieldset legend').html(addText);

    $cb_clear = $this.closest('.controls').find('.combobox-clear');
    if ($cb_clear.is(":visible")) {
      $cb_clear.click();
    }

    if (f.is(':visible')) {
      $this.html(addText).css('color', '#005580').attr('title', $this.data('title')).attr('data-title', originalTitle);
      f.hide();
      f.find('input,select').not('[id$="account_id"],[id$="user_id"],[class$="contact-country"],[class$="contact-state"]').val('');
      f.find(':input').prop('disabled', true);
    } else {
      f.show().find('legend').css('font-size', '0.85em');
      $this.html(removeText).css('color', 'red').attr('title', $this.data('title')).attr('data-title', originalTitle);
      f.show();
      f.find(':input').prop('disabled', false);
      f.find(':input').not('[id$="account_id"],[id$="user_id"],[class$="contact-country"],[class$="contact-state"]').val(null);
      f.find("input:text").first().focus();

      nameField = f.find('input[name$="[supplier_attributes][first_name]"]');
      if (nameField.length > 0) {
        namespace = nameField.attr('name').replace('[supplier_attributes][first_name]', '');
        $('[name="' + namespace + '[supplier_id]"]').val(null);
        $('[name="' + namespace + '[supplier_type]"]').val('Contact');
      }

      $this.closest('.add-new-supplier').siblings('.vendor.cost').find('.supplier-display-name').val(null);
      $this.closest('.field').siblings('[name$="[contact_id]"]').val(null);

      vendorFields = f.parent().find('.vendor-fields');
      vendorFields.find(':input').attr('disabled', true);
      vendorFields.hide();

      vendorLink = $this.siblings('.add-vendor');
      originalTitle = vendorLink.attr('title');
      vendorLink.html('Add&nbsp;new&nbsp;vendor').css('color', '#005580').attr('title', vendorLink.data('title')).attr('data-title', originalTitle);

    }
    f.addClass('styled-inner-form');

    $(this).trigger('motorlot:contact-added');

    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  $("form.edit_deal, form.new_deal").on("click", ".edit-contact-button", function(event, force_close_param) {
    var $button = $(this);
    var $contact = $button.closest(".control-group.contact");
    var contact_id = $contact.find("[name$=\"[contact_id]\"]").val();
    var force_close = (force_close_param === 'true');
    
    if (contact_id || force_close) {
        var $contact_form = $contact.find(".contact-form");
        
        if ($contact_form.is(":visible") || force_close) {
            $contact_form.find(':input').attr('disabled', true);
            $contact_form.hide();
            $button.html("View/edit contact");
        } else {
            $button.html("Loading...");
            $contact_form.find(':input').attr('disabled', false).val(null);
            
            $.get("/leads/" + contact_id + ".json", function(data) {
                $contact_form.find(":input").each(function(idx, el) {
                    var $input = $(el);
                    var name = $input.attr("name");
                    var match;
                    
                    if (name && (match = name.match(/\[(?:customer_attributes|contact_attributes)\]\[(.+)\]/))) {
                        var field = match[1];
                        $input.val(data.contact[field]);
                    }
                });
                $contact_form.addClass('styled-inner-form');
                $contact_form.show();
                $button.html("Hide contact details");
            });
        }
    }
  });
  $("form.edit_deal, form.new_deal").on("click", ".save-and-add-contact", function(event) {
    var $self = $(this);
    var attr_name = $self.data('attr-name');
    var $contact_group = $self.closest(".control-group.contact");
    var $select = $contact_group.find("[name$=\"[contact_id]\"]");
    var contact_id = $select.val();
    var name = $select.attr("name").replace("[contact_id]", "[" + attr_name + "]");
    var params = $("form.edit_deal, form.new_deal").find("[name^=\"" + name + "\"]").serialize();
    var regex = new RegExp(encodeURIComponent(name), "g");
    var contact_params = params.replace(regex, "contact");
    var csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    
    $.ajax({
        url: (contact_id ? "/leads/" + contact_id + ".json" : "/leads.json"),
        type: (contact_id ? "PUT" : "POST"),
        data: contact_params,
        headers: {
            'X-CSRF-Token': csrfToken
        },
        success: function(xhr) {
          var id = xhr.contact.id;
          var display = xhr.contact.last_name + ", " + xhr.contact.first_name + " " + xhr.contact.company_name;
          $select.val(xhr.contact.id);
          $contact_group.find(".combobox").val(display);
          $(".error_explanation").remove();
          $(".alert").remove();
          if (contact_id) {
              $contact_group.find(".edit-contact-button").click();
              $("form.edit_deal, form.new_deal").before("<div class=\"alert alert-success\">Contact updated successfully</div>");
          } else {
              $contact_group.find(".add-contact").click();
              $("form.edit_deal, form.new_deal").before("<div class=\"alert alert-success\">Contact created successfully</div>");
          }
          window.scrollTo(0, 0);
        },
        error: function(xhr) {
          var responseJSON = $.parseJSON(xhr.responseText);
          $(".error_explanation").remove();
          $(".alert").remove();
          $("form.edit_deal, form.new_deal").before("<div class=\"error_explanation\">" + responseJSON.error_explanation + "</div>");
          window.scrollTo(0, 0);
        }
    });
    event.preventDefault();
    event.stopPropagation();
    return false;
  });

  $('body').on("click", ".remove_deferred_down.red", function(e) {
    e.preventDefault();
    var $deferred_down = $(this).closest("li");
    $deferred_down.find('.calculate').val('0');
    $deferred_down.find('.destroy').val('1');
    $deferred_down.hide();
    deal.calculate();
  });

  $(document).ready(function() {
    $('#show_accessories_link').click(function(e) {
      e.preventDefault(); // Prevent default link behavior

      $('#deal_accessories_cost_wrapper').toggleClass('hidden');
      var linkText = $('#deal_accessories_cost_wrapper').hasClass('hidden') ? 'Show cost' : 'Hide cost';
      $(this).text(linkText);
      if (linkText === 'Hide cost') {
        $(this).removeClass('blue-text').addClass('red-text');
      } else {
        $(this).removeClass('red-text').addClass('blue-text');
      }
    });
    $('#show_cost_link').click(function(e) {
      e.preventDefault(); // Prevent default link behavior

      $('#gap_contract_cost_wrapper').toggleClass('hidden');
      var linkText = $('#gap_contract_cost_wrapper').hasClass('hidden') ? 'Show cost' : 'Hide cost';
      $(this).text(linkText);
      if (linkText === 'Hide cost') {
        $(this).removeClass('blue-text').addClass('red-text');
      } else {
        $(this).removeClass('red-text').addClass('blue-text');
      }
    });
  });

  $(document).ready(function() {
    var error_count = 0;

    function vin_load_notice(message, delay) {
      $(".load_notice").html(message).show().delay(delay).fadeOut(2000);
    }

    $("#inventory_vin").on('input', function() {
      if ($(this).val().length === 17) {
        vin_load_notice("VIN is invalid, try again", 2000);
        error_count = error_count + 1;
        if (error_count >= 1) {
          vin_load_notice("Having troubles decoding?", 10000);
        }
      } else {
        vin_load_notice("Please enter a 17 digit VIN to decode", 2000);
      }
    });
  });
  $("form.edit_deal, form.new_deal").on("click", ".edit-inventory .save-inventory", function(event) {
    var $form = $(this).closest("form");
    var name = "deal[vehicle_attributes]";

    $form.find("input.number, input.currency, input.currency_decimal").each(function() {
      var inputValue = $(this).val();
      if (!isNaN(inputValue)) {
          $(this).val(parseFloat(inputValue));
      }
    });
    var params = $form.find("[name^='" + name + "']").serialize();
    var regex = new RegExp(encodeURIComponent(name), "g");
    var inventory_params = params.replace(regex, "inventory");
    var csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    $.ajax({
        url: "/inventory/" + $form.find("[name='deal[vehicle_id]']").val() + ".json",
        type: "PUT",
        data: inventory_params,
        headers: {
            'X-CSRF-Token': csrfToken
        },
        success: function(xhr) {
            external_ui.reload_vsc_rates();
            $form.find(".edit-inventory-button").click();
            $(".error_explanation, .alert").remove();
            $form.before("<div class=\"alert alert-success\">Inventory successfully updated</div>");
            window.scrollTo(0, 0);
        },
        error: function(xhr) {
            var responseJSON = $.parseJSON(xhr.responseText);
            $(".error_explanation, .alert").remove();
            $form.before("<div class=\"error_explanation\">" + responseJSON.error_explanation + "</div>");
        }
    });
  });

  $('.deals, .inventory-purchasing').on('change', '.include-checkbox', function () {
    var targ = $(this);
    var fields = targ.closest('.field, .row').nextAll('.included-fields').first();
    var isChecked = targ.is(":checked");
    if (isChecked) {
      fields.show();
    } else {
      fields.find("input").not('[id$="account_id"]').val('');
      fields.find('select').val('');
      fields.hide();
    }
  })

  $('#deal_lienholder_attributes_vendor_id').on('change', function() {
    var vendorId = $('#deal_lienholder_attributes_vendor_id').val();
    if (vendorId) {
      $.get('/api/vendors/' + vendorId + '.json', function(data) {
        $('#deal_lienholder_attributes_account_number').val(data.account_number);
      });
    }
  });

  $(document).ready(function() {
    // Uncheck other checkboxes when a checkbox is checked
    $('.calculate.type_of_sale').on('change', function() {
        if ($(this).is(':checked')) {
            $('.calculate.type_of_sale').not(this).prop('checked', false);
        }
    });
    $('.calculate.finance_payments').on('change', function() {
        if ($(this).is(':checked')) {
            $('.calculate.finance_payments').not(this).prop('checked', false);
        }
    });
    $('.calculate.interest_computation').on('change', function() {
        if ($(this).is(':checked')) {
            $('.calculate.interest_computation').not(this).prop('checked', false);
        }
    });
    $('.calculate.deal_late_fees_type').on('change', function() {
        if ($(this).is(':checked')) {
            $('.calculate.deal_late_fees_type').not(this).prop('checked', false);
        }
    });

    $('.adjustment_option_box').on('change', function() {
        $('.adjustment_option_box').not(this).prop('checked', false);
    });

    $('.adjustment_target_box').on('change', function() {
      $('.adjustment_target_box').not(this).prop('checked', false);
    });

    $(".pricing-adjustment-active .target-options [name='adjustment_target']").on('change', function(event) {
      $(".pricing-adjustment-active .target-options [name$='_adjustment']").prop('disabled', true);
      
      var option = $(".pricing-adjustment-active .target-options [name='adjustment_target']:checked").val();
      $(".pricing-adjustment-active .target-options [name='" + option + "_adjustment']").prop('disabled', false);
      
      if ($("#adjustment_target_amount_financed").is(':checked')) {
          $(".pricing-adjustment-active .adjustment-options #adjustment_option_interest_rate").prop('disabled', true);
      } else {
          $(".pricing-adjustment-active .adjustment-options #adjustment_option_interest_rate").prop('disabled', false);
      }
    });
    const dealType = function () {
        const checkedCheckbox = $("input[name='deal[type_of_sale]']:checkbox:checked");
        if (checkedCheckbox.length > 0) {
            return checkedCheckbox.val();
        } else {
            return null; // No checkbox is checked
        }
    };

    $('[name="deal[type_of_sale]"]').on('change', function (event) {
      toggle_type_of_sale($(this).val(), true);
    });

    if ($('[name="deal[type_of_sale]"]').length > 0) {
      toggle_type_of_sale();
    }

    function toggle_type_of_sale(value, force_values) {
      if (!value) {
        value = $('[name="deal[type_of_sale]"]:checked').val();
      }

      if (value === 'cash' || value === 'wholesale') {
        if (force_values) {
          $('#deal_no_of_payments').val(1);
          $('#deal_interest_rate').val(0);
          $('#deal_frequency').val('0');
          $('#deal_interest_method').val('');
        }
        $('#bhph_fields').hide();
        $('#outside_financing_fields').hide();
      } else if (value === 'bhph') {
        $('#bhph_fields').show();
        $('#outside_financing_fields').hide();
        if (force_values) {
          $('#deal_frequency').val('12');
        }
      } else if (value === 'outside_financing') {
        $('#bhph_fields').hide();
        $('#outside_financing_fields').show();
        if (force_values) {
          $('#deal_frequency').val('12');
        }
      }
    }

    const updateSaleInformationFields = function () {
      const typeOfSale = dealType();
      switch (typeOfSale) {
          case 'bhph':
          case 'outside_financing':
              $('.financial_terms_container').removeClass('hidden');
              $('.cash-deferred-down-container').removeClass('hidden');
              if ($('#deal_first_payment').val() === '') {
                  $('#deal_first_payment').val(moment().add('months', 1).format('L'));
              }
              break;
          case 'cash':
              $('.financial_terms_container').addClass('hidden');
              $('.cash-deferred-down-container').addClass('hidden');
              break;
          case 'wholesale':
              $('.financial_terms_container').addClass('hidden');
              $('.cash-deferred-down-container').addClass('hidden');
              if ($('#deal_country').val() !== 'CA') {
                  const fields = [
                      'doc_prep',
                      'license_fee',
                      'title_fee',
                      'lien_fee',
                      'transfer',
                      'city_tax_rate',
                      'state_tax_rate',
                      'county_tax_rate',
                      'business_tax_rate',
                      'late_fees',
                      'late_fees_type'
                  ];
                  fields.forEach((f) => {
                      $(`#deal_${f}`).val(0);
                  });
                  $("input[name*='deal[custom_fields]']").each(function () {
                      $(this).val('0');
                  });
              }
              break;
          default:
              // Handle other cases or no selection
              break;
        }
    };

    // Event listener for checkbox change
    $("input[name='deal[type_of_sale]']:checkbox").on('change', function () {
        updateSaleInformationFields();
    });
  });
  document.addEventListener("DOMContentLoaded", function() {
    var activateButton = document.querySelector(".pricing-adjustment-inactive button.activate");
    if (activateButton) {
      activateButton.addEventListener("click", function(event) {
        event.preventDefault();
        event.stopPropagation();
        var input = document.querySelector("#amount_financed_adjustment");
        document.querySelector(".pricing-adjustment-active").style.display = "block";
        document.querySelector(".pricing-adjustment-inactive").style.display = "none";
        input.value = input.getAttribute("data-original-value");
        // Optionally, call the formatCurrency() function here if needed
        return false;
      });
    } else {
        console.error("Activate button not found");
    }
  });

  function disableAdjustment(event) {
    event.preventDefault();
    event.stopPropagation();
    var input = document.querySelector("#amount_financed_adjustment");
    document.querySelector(".pricing-adjustment-active").style.display = "none";
    document.querySelector(".pricing-adjustment-inactive").style.display = "block";
    input.value = input.getAttribute("data-original-value");
    return false;
  }

  document.addEventListener("DOMContentLoaded", function() {
    var deactivateButton = document.querySelector(".pricing-adjustment-active button.deactivate");
    if (deactivateButton) {
        deactivateButton.addEventListener("click", disableAdjustment);
    } else {
        console.error("Deactivate button not found");
    }
  });

  class Deal {
    constructor(el) {
      this.deal = $(el);
      this.deal.on('change', '[name="deal[type_of_sale]"]', this.update_sale_information_fields.bind(this));
      this.deal.on('change', '.deal_late_fees_type', this.updateLateFees.bind(this));

      this.init();
      this.deferred_down_5_limit();
    }

    init() {
      this.update_sale_information_fields();
      this.updateLateFees();
    }

    updateLateFees() {
      const late_fees_type = this.deal.find('.deal_late_fees_type:radio:checked').val();
      const late_fees = $('#deal_late_fees');

      if (late_fees_type === 'percentage') {
        late_fees.removeClass('currency_decimal').addClass('decimal');
      } else {
        late_fees.removeClass('decimal').addClass('currency_decimal');
      }

      late_fees.trigger('blur');
    }

    deferred_down_5_limit() {
      this.deal.on('click', '#list-deferred_downs a.add_child', (e) => {
        if ($('#list-deferred_downs li:visible').length >= 5) {
          alert('Too many deferred downs');
          e.stopImmediatePropagation();
          return false;
        }
      });
    }

    deal_type() {
      return this.deal.find('input[name="deal[type_of_sale]"]:radio:checked').val();
    }

    update_sale_information_fields() {
      switch (this.deal_type()) {
        case 'bhph':
        case 'outside_financing':
          this.deal.find('.financial_terms_container').removeClass('hidden');
          this.deal.find('.cash-deferred-down-container').removeClass('hidden');

          if (this.deal.find("#deal_first_payment").val() === '') {
            this.deal.find("#deal_first_payment").val(moment().add('months', 1).format('L'));
          }
          break;
        case 'cash':
        case 'wholesale':
          this.deal.find('.financial_terms_container').addClass('hidden');
          this.deal.find('.cash-deferred-down-container').addClass('hidden');

          if ($('#deal_country').val() !== 'CA') {
            const fields = [
              'doc_prep', 'license_fee', 'title_fee', 'lien_fee', 'transfer',
              'city_tax_rate', 'state_tax_rate', 'county_tax_rate', 'business_tax_rate',
              'late_fees', 'late_fees_type'
            ];
            fields.forEach(f => $("#deal_" + f).val(0));
            $("input[name*='deal[custom_fields]']").each(function() {
              $(this).val("0");
            });
          }
          break;
        default:
          break;
      }
    }

    calculate() {

      const el = $(deal_form_selector);
      const url = el.attr("id").match(/\d+$/) ? "/deals/" + el.attr("id").match(/\d+$/)[0] + "/calculate.json" : "/deals/calculate.json";
      var csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      el.ajaxForm({
        url: url,
        dataType: 'json',
        type: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken
        },
        success: (data, status, form) => {
    
          const deal_data = data;
          $("#sidebar .summary .summary-field").each(function() {
            const attribute = $(this).attr("data-deal-field");
            const attribute_cents = attribute + '_cents';

            if (attribute in deal_data || attribute_cents in deal_data) {
              if (deal_data[attribute] && deal_data[attribute]["fractional"]) {
                const value = deal_data[attribute]["fractional"] / 100;
                $(this).find(".summary-data").html(value);
              } else {
                let value = deal_data[attribute] || (deal_data[attribute_cents] / 100);

                if (deal_data[attribute] && attribute === "no_of_payments") {
                  let no_of_payments = Math.ceil(deal_data.no_of_payments);

                  if (deal_data.final_payment_amount_cents && deal_data.final_payment_amount_cents !== deal_data.payment_cents) {
                    no_of_payments += 1;
                  }

                  $(this).find(".summary-data").html(no_of_payments + "(" + deal_data.frequency_type + ")");
                } else {
                  $(this).find(".summary-data").html(value);
                  $(this).find(".summary-data").not('text');
                }
              }
            }
          });

          if ($("#deal_calculate_financing_by_no_of_payments_true").is(":checked")) {
            $("#deal_payment").val(deal_data.payment_cents / 100);
            $("#sidebar .summary-field.final_payment_amount").hide();
          } else {
            $("#sidebar .summary-field.final_payment_amount").show();
            $("#deal_no_of_payments").val(Math.ceil(deal_data.no_of_payments));
          }

          $("#deal_cash_price").val(deal_data.cash_price_cents / 100);
          $("#deal_cash_down").val(deal_data.cash_down_cents / 100);
          // $("#deal_deferred_down").val(deal_data.deferred_down.fractional / 100);
          $("#deal_interest_rate").val(deal_data.interest_rate);

          // $("#amount_financed_adjustment").val(deal_data.amount_financed.fractional / 100).data('original-value', deal_data.amount_financed.fractional / 100);
          $("#total_payments_adjustment").val(deal_data.total_payments_cents / 100).data('original-value', deal_data.total_payments_cents / 100);
          $("#payment_adjustment").val(deal_data.payment_cents / 100).data('original-value', deal_data.payment_cents / 100);
          $("#no_of_payments_adjustment").val(deal_data.no_of_payments).data('original-value', deal_data.no_of_payments);

          $('#deal_receivable_amount').val(deal_data.receivable_amount_cents / 100);
          $('#deal_reserve_amount').val(deal_data.reserve_amount_cents / 100);
          $('#deal_total_amount_to_receive_from_lien_holder').val(deal_data.total_amount_to_receive_from_lien_holder.fractional / 100);

          $('.summary').removeClass('loading');
        }
      });

      const form_method = $("input[name='_method']");
      if (form_method && form_method.attr("value") === "patch") {
        form_method.attr("value", "post");
      }
      el.submit();
      // format_number();
      el.ajaxFormUnbind();
      form_method.attr("value", "patch") 
      if (form_method && form_method.attr("value") === "post"){
        form_method.attr("value", "patch");
      }
    }
  }

  var deal_form_selector = ".new_deal, .edit_deal"

  var deal = new Deal(deal_form_selector)

  document.addEventListener("DOMContentLoaded", function() {
    var adjustButton = document.querySelector(".pricing-adjustment-active button.make-adjustment");
    if (adjustButton) {
      adjustButton.addEventListener("click", function(event) {
        // Find the input field
        var adjustPriceInput = document.querySelector(".pricing-adjustment-active input[name='adjust_price']");
        if (adjustPriceInput) {
            // Set the input value and perform calculations
            adjustPriceInput.value = 'true'; // or any value you need
            if (typeof deal !== "undefined" && typeof deal.calculate === "function") {
                deal.calculate();
            } else {
                console.error("deal.calculate is not a valid function");
            }
            
            // Reset the input value
            adjustPriceInput.value = null;
            disableAdjustment(event); // Call your disableAdjustment function
        } else {
            console.error("Input field for adjust_price not found");
        }
      });
    } else {
        console.error("Make adjustment button not found");
    }
  });

  const formatCurrency = (amount, currencyCode) => {
    return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
    }).format(amount);
  };

  const changeDecimalFieldsOnSubmit = () => {
      $(".decimal").each(function () {
          const decimalValue = parseFloat($(this).text()); // Assuming the decimal value is in text content
          const formattedValue = formatCurrency(decimalValue, 'USD'); // Change 'USD' to the desired currency code
          $(this).text(formattedValue); // Update the text content with the formatted value
      });
  };

  const calculate_financing = () => {
    const deal = new Deal(deal_form_selector);
    changeDecimalFieldsOnSubmit();
    $('.summary').addClass('loading');
    deal.calculate();
  };

  $(".deals").on("change", ".calculate", () => {
    calculate_financing();
  });
})

$(document).ready(function() {
  $('.fas.fa-expand-alt').off('click').on('click', function() {
    var childTradeId = $(this).data('child-trade');
    var inset = $('#child-trade-' + childTradeId);

    if (inset.is(':visible')) {
      inset.hide(); // Hide the inset if it's currently visible
    } else {
      inset.show(); // Show the clicked inset
    }
    $(this).off('click'); // Optionally, unbind the click event from the current element
  });
});

document.addEventListener("DOMContentLoaded", function() {
  var serviceContractElement = document.getElementById("no_service_contract");
  if (serviceContractElement) {
    if (!serviceContractElement.querySelector('input[name="service_contract_type"]').value) {
        serviceContractElement.style.display = "none";
    }
  }
  var basicContractElement = document.getElementById("basic_service_contract");
  if (basicContractElement) {
    if (!basicContractElement.querySelector('input[name="service_contract_type"]').value) {
        basicContractElement.style.display = "none";
    }
  }

  // Show the elements you want to display initially
  const externalVsc = document.querySelector('.external-vsc-link-block.change-vsc-type');
  
  const otherVsc = document.querySelector('.other-vsc-link-block.change-vsc-type');
    
  if (externalVsc) {
      externalVsc.style.display = 'inline-block';
  }
  if (otherVsc) {
      otherVsc.style.display = 'inline-block';
  }
});

$('.commission_type').on('change', function() {
  if ($(this).is(':checked')) {
      $('.commission_type').not(this).prop('checked', false);
  }
});

$(document).ready(function() {
  // Find the parent element with class 'vendor' and then find its child element with class 'choices'
  var vendorChoices = $('.vendor').find('.choices');
  vendorChoices.css('width', '75%');
  $('.inner-form-vendor.vendor-gapcontract').each(function() {
      $(this).find('fieldset').css('background-color', 'white');
      $(this).find('.card-body').css('background-color', 'white');
  });
  $('.inner-form-vendor.vendor-insurer').each(function() {
      $(this).find('fieldset').css('background-color', 'white');
      $(this).find('.card-body').css('background-color', 'white');
  });
});

$.fn.toNumber = function() {
  return this.each(function() {
    var value = $(this).val();
    if (value) {
      var numberValue = parseFloat(value.replace(/[^0-9.]/g, ''));
      $(this).val(numberValue);
    }
  });
};

$(".deals").on("click", ".save-trade-in", function(event) {
  var $el = $(this);
  var dealId = $el.data("deal-id");
  var csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  if (dealId) {
    // Use find instead of siblings to correctly locate the inputs
    var $input = $el.closest(".inventory_fields").find("[name^='deal[trade_ins_attributes]']");
    
    // Check if $input is not found or if name attribute is undefined
    if ($input.length === 0 || !$input.attr("name")) {
      console.error("Input with name starting 'deal[trade_ins_attributes]' not found.");
      return false;
    }
    
    var idx = $input.attr("name").match(/\[\d+\]/)[0];
    var name = "deal[trade_ins_attributes]" + idx;
    var $form = $("form.edit_deal");
    $form.find("input.number, input.currency, input.currency_decimal").toNumber();
    var params = $form.find("[name^='" + name + "']").serialize();
    var tradeInId = $el.data('trade-in-id');
    var child_trade_id = $el.data("child-trade");
    var requestType, url;
    if ($el.data('trade-in-id')) {
      requestType = 'PUT';
      url = "/deals/" + dealId + "/trade_ins/" + tradeInId;
    } else {
      requestType = 'POST';
      url = "/deals/" + dealId + "/trade_ins";
    }
    $.ajax({
      url: url,
      type: requestType,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      data: params + "&child_block=" + child_trade_id
    });
  }
  $el.closest(".accordion-body").collapse("hide");
  return false;
});


  // Remove trade-in
$(".deals").on("click", ".remove-trade-in", function(event) {
  event.preventDefault();
  var $el = $(this);
  $(".error_explanation").remove();
  $(".alert-success").remove();

  var accordionGroup = $el.closest(".accordion-group");
  var csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  if (!$el.data('new')) {
    var dealId = $el.data('deal-id');
    var tradeId = $el.data('trade-in-id');

    // remove hidden field with inventory id
    accordionGroup.next().remove();

    $.ajax({
      url: "/deals/" + dealId + "/trade_ins/" + tradeId,
      type: 'DELETE',
      headers: {
        'X-CSRF-Token': csrfToken
      },
      dataType: 'json',
      success: function() {
        $('form.edit_deal').before('<div class="alert alert-success">Trade-in successfully removed</div>');
      }
    });
  }
  accordionGroup.remove();
  window.scrollTo(0, $("#trade-ins-accordion").offset().top);
});

$("form.edit_deal .print-forms").on("click", function(event) {
  var $form = $(this).parents("form");

  if ($form.find("[data-paid-form=true]").is(":checked") && PAID_FORMS_ENABLED !== true) {
      confirmPaidForms();
      return false;
  } else {
      $form.find("input[name=\"submit_type\"]").val("print");

      // Set the print_token cookie using native JavaScript
      document.cookie = "print_token=" + $form.find("input[name=\"print_token\"]").val() + "; path=/";

      $form.find(".save-forms, .print-forms, .email-forms").prop("disabled", true).addClass("disabled");
      $form.find(".print-forms").html("Generating pdf...");
      $form.submit();
  }
});
$(document).ready(function() {
  var printTokenCookie = document.cookie.replace(/(?:(?:^|.*;\s*)print_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
  var printToken = parseInt(printTokenCookie, 10);

  if (!isNaN(printToken)) {
    document.cookie = "print_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // Remove print_token cookie

    var form = $("form.edit_deal");

    $(".print-forms").click(function() {
        var printTokenInputValue = parseInt(form.find("input[name=\"print_token\"]").val(), 10);
        if (!isNaN(printTokenInputValue) && printToken < printTokenInputValue) {
            form.find(".save-forms, .print-forms, .email-forms").prop("disabled", true).addClass("disabled");
            form.find(".print-forms").html("Generating pdf...");

            var pdfDownloadToken = "" + printToken;
            var intervalId = setInterval(function() {
                var pdfDownloadTokenCookie = document.cookie.replace(/(?:(?:^|.*;\s*)pdf_download_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
                if (pdfDownloadTokenCookie === pdfDownloadToken) {
                    form.find(".save-forms, .print-forms, .email-forms").prop("disabled", false).removeClass("disabled");
                    form.find(".print-forms").html("Save forms & print all");
                    clearInterval(intervalId);
                }
            }, 100);

            window.location.href = form.attr("action") + "/forms/print_all.pdf?pdf_download_token=" + pdfDownloadToken;
        }
    });
  }
});
$('.deal-add-forms').on('click', function (e) {
  var $button = $(this), form_ids;

  form_ids = $('input.add-additional-form-checkbox:checked').map(function () {
      return parseInt($(this).data('form-id'), 10);
  });

  var csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  $.post('/deals/' + $button.data('deal-id') + '/forms/insert-forms.js', {
      'form_ids[]': $.makeArray(form_ids), 
      authenticity_token: csrfToken
  });

  $button.closest('.accordion-body').collapse('hide');

  e.preventDefault();
  e.stopPropagation();
  return false;
});

$('body').on("change", ".use_me", function(){
  $(this).siblings(".destroy").val($(this).is(":checked") ? "0" : "1");
});

$("form.edit_deal .email-forms").on("click", function() {
  var $form = $(this).parents("form");

  $(".inner-main .alert").remove();
  if (!$form.find(".email-forms-to").val()) {
      $(".inner-main").prepend("<div class=\"alert alert-error\">Please specify an email address to send email to.</div>");
      window.scrollTo(0, 0);
      return false;
  } else if ($form.find("[data-paid-form=true]").is(":checked") && PAID_FORMS_ENABLED != true) {
      confirmPaidForms();
      return false;
  } else {
      $form.find("input[name=\"submit_type\"]").val("email");
      $form.find(".save-forms, .print-forms, .email-forms").prop("disabled", true).addClass("disabled");
      $form.find(".email-forms").html("Emailing pdf...");
      $form.submit();
  }
});

function confirmPaidForms() {
  // /deals/:deal_id
  var link = document.location.pathname + '/forms/enable_paid_forms';
  Util.alert("You're attempting to use a paid form.<br /><br />Please confirm that you'd like to use paid forms: LAW and Wolters Kluwer are $3/copy.", {
      title: 'Confirm paid forms',
      width: 420,
      buttons: {
        'Enable Paid Forms': function() {
            $.ajax({
                url: link + '.json',
                dataType: 'text',
                type: 'post',
                success: function(content, status, xhr) {
                    document.location.reload(true);
                }
            });
        },
        'Cancel': function() {
            $(this).dialog('close');
            return false;
        }
      }
  });
}
$("table.tablesorter").tablesorter({
  theme : 'default',
  headers: {
    4: { sorter: false, filter: false }
  }
});

$(".use-form-folder").on("change", function() {
  var $checkbox = $(this);
  var checked = $checkbox.is(":checked");
  
  $(".use-form-in-folder-" + $checkbox.val()).prop("checked", checked);
  $(".use-form-in-folder-" + $checkbox.val()).parents(".form-in-folder").find(".destroy").val(checked ? "0" : "1");
  
  if (checked) {
      $checkbox.parents(".form").find(".form_fields").show();
  } else {
      $checkbox.parents(".form").find(".form_fields").hide();
  }
});

$(".use-form-in-folder").on("change", function() {
    var $checkbox = $(this);
    var checked = $checkbox.is(":checked");
    
    if (checked) {
        $checkbox.parents(".form-in-folder").find(".form_fields").show();
        $checkbox.parents(".form-in-folder").find(".destroy").val("0");
    } else {
        $checkbox.parents(".form-in-folder").find(".form_fields").hide();
        $checkbox.parents(".form-in-folder").find(".destroy").val("1");
    }

    var folderId = $checkbox.data("form-folder-id");
    var uncheckedSiblings = $.grep($(".use-form-in-folder-" + folderId), function(el) {
        return !$(el).is(":checked");
    });
    $("#use_form_folder_" + folderId).prop("checked", (!uncheckedSiblings || uncheckedSiblings.length === 0));
});

$(".use-form-folder").each(function() {
    var uncheckedChildren = $.grep($(".use-form-in-folder-" + $(this).val()), function(el) {
        return !$(el).is(":checked");
    });
    $(this).prop("checked", (!uncheckedChildren || uncheckedChildren.length === 0));
});

