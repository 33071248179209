import { defineComponent, ref, onMounted, computed } from 'vue';

export default defineComponent({
  name: 'message',
  template: '#message-template',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const from = computed(() => props.message.outgoing ? 'me' : 'you');
    const loadedData = ref(null);
    const loading = ref(false);

    const deleteClicked = () => {
      emit('remove', props.message);
    };

    const loadData = async () => {
      loading.value = true;
      try {
        const response = await fetch(`/dialogs/${props.message.dialog_id}/mark_read`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        loadedData.value = data.messages;
      } catch (error) {
        console.error('Error loading conversation:', error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      loadData();
    });

    return {
      from,
      loadedData,
      loading,
      deleteClicked,
    };
  },
});
