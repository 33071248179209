<template lang="pug">
div(class="table-responsive")
  table(class="table align-items-center")
    thead
      tr
        th
          input(type="checkbox" ref="selectAllCheckbox" @change="toggleSelectAll" :checked="isAllSelected" :class="{'indeterminate': selectAllCheckbox && selectAllCheckbox.indeterminate}")
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Name
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Status
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Created At
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Phone
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Home phone
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Business phone
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Action
    tbody
      LeadItem(v-for='lead in data' :lead='lead' :key='lead.id' 
               @remove="removeItem" @archive="removeItem" @unarchive="removeItem" 
               @toggleSelect="toggleSelect" 
               :isSelected="selectedLeads.includes(lead.id)")

div(v-intersection="fetchAdditionalData" class="observer")
  Spinner(v-if="loading")
</template>

<script>
import LeadItem from './LeadItem.vue'
import Spinner from '../Spinner.vue'
import loadData from '../../hooks/loadData'
import dataHelpers from '../../mixins/dataHelpers'
import { ref, watch, watchEffect, computed, onMounted, onBeforeUnmount } from 'vue'

export default {
  components: { LeadItem, Spinner },
  props: {
    api_endpoint: {
      type: String,
      required: true
    }
  },
  mixins: [dataHelpers],
  setup(props) {
    const { fetchData, fetchAdditionalData, data, loading, currentPage, totalPages } = loadData(props.api_endpoint, 25)
    const selectedLeads = ref([]) // This is reactive
    const selectAllCheckbox = ref(null) // Reference to the checkbox

    const handleButtonClick = () => {
      console.log('Button clicked!');
      // Add more logic here
    };

    const isAllSelected = computed(() => {
      return selectedLeads.value.length === data.value.length && data.value.length > 0
    })

    watchEffect(() => {
      const leadCountElement = document.getElementById('selected-lead-count')
      if (leadCountElement) {
        leadCountElement.textContent = selectedLeads.value.length
      }
    })

    watchEffect(() => {
      if (selectAllCheckbox.value) {
        selectAllCheckbox.value.indeterminate = selectedLeads.value.length > 0 && selectedLeads.value.length < data.value.length
      }
    })

    const toggleSelectAll = (event) => {
      if (event.target.checked) {
        selectedLeads.value = data.value.map(lead => lead.id)
      } else {
        selectedLeads.value = []
      }
    }

    const toggleSelect = (leadId) => {
      const index = selectedLeads.value.indexOf(leadId)
      if (index > -1) {
        selectedLeads.value.splice(index, 1) // Deselect lead
      } else {
        selectedLeads.value.push(leadId) // Select lead
      }
    }

    const archiveSelectedLeads = async () => {
      if (selectedLeads.value.length === 0) return; // No leads selected
      try {
        const response = await fetch('/api/leads/bulk_archive', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') // Add CSRF token here
          },
          body: JSON.stringify({ lead_ids: selectedLeads.value })
        });

        if (response.ok) {
          selectedLeads.value = [];
          $('#bulk-actions').hide(); // Assuming bulkActions is defined somewhere
          $('#selected-lead-text').hide();
          $('#selected-lead-count').hide();
          await fetchData();
        } else {
          console.error('Failed to archive leads');
        }
      } catch (error) {
        console.error('Error archiving leads:', error);
      }
    }

    const unArchiveSelectedLeads = async () => {
      if (selectedLeads.value.length === 0) return; // No leads selected
      try {
        const response = await fetch('/api/leads/bulk_unarchive', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') // Add CSRF token here
          },
          body: JSON.stringify({ lead_ids: selectedLeads.value })
        });

        if (response.ok) {
          selectedLeads.value = [];
          $('#bulk-actions').hide(); // Assuming bulkActions is defined somewhere
          $('#selected-lead-text').hide();
          $('#selected-lead-count').hide();
          await fetchData();
        } else {
          console.error('Failed to archive leads');
        }
      } catch (error) {
        console.error('Error archiving leads:', error);
      }
    }

    const deleteSelectedLeads = async () => {
      if (selectedLeads.value.length === 0) return;
      try {
        const response = await fetch('/api/leads/bulk_destroy', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({ lead_ids: selectedLeads.value })
        });

        if (response.ok) {
          selectedLeads.value = [];
          $('#bulk-actions').hide(); // Assuming bulkActions is defined somewhere
          $('#selected-lead-text').hide();
          $('#selected-lead-count').hide();
          await fetchData();
        } else {
          // Handle error response
          console.error('Failed to delete leads');
        }
      } catch (error) {
        console.error('Error deleting leads:', error);
      }
    }

    onMounted(fetchData)
    onMounted(() => {
      const archiveButton = document.getElementById('archive-btn');
      const deleteButton = document.getElementById('delete-btn');
      const unArchiveButton = document.getElementById('unarchive-btn');

      if (archiveButton) {
        archiveButton.addEventListener('click', () => {
          archiveSelectedLeads();
        });
      }

      if (unArchiveButton) {
        unArchiveButton.addEventListener('click', () => {
          unArchiveSelectedLeads();
        });
      }

      if (deleteButton) {
        deleteButton.addEventListener('click', () => {
          deleteSelectedLeads();
        });
      }

      // Clean up the event listeners when the component is unmounted
      onBeforeUnmount(() => {
        if (archiveButton) {
          archiveButton.removeEventListener('click', () => {
            console.log("hello archived ");
          });
        }
        if (deleteButton) {
          deleteButton.removeEventListener('click', () => {
            console.log("hello delete ");
          });
        }
        if (unArchiveButton) {
          unArchiveButton.removeEventListener('click', () => {
            console.log("hello unarchived ");
          });
        }
      });
    });

    return {
      data,
      fetchData,
      fetchAdditionalData,
      loading,
      currentPage,
      totalPages,
      selectedLeads,
      selectAllCheckbox,
      isAllSelected,
      toggleSelectAll,
      toggleSelect,
      handleButtonClick,
      archiveSelectedLeads, // Add this
      deleteSelectedLeads // Add this
    }
  }
}
</script>

<style>
/* Reset checkbox appearance */
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #d3cdcd;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

/* Style for checked state */
input[type="checkbox"]:checked {
  background-color: #5e72e4;
}

/* Custom tick mark */
.lead-section input[type="checkbox"]:checked::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .67rem;
}

/* Style for indeterminate state */
input[type="checkbox"].indeterminate {
  background-color: #5e72e4;
  position: relative;
}

/* Custom indicator for indeterminate state */
input[type="checkbox"].indeterminate::after {
  content: "";
  display: block;
  width: 50%;
  height: 2px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translateY(-50%); 
}

</style>