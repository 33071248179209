<template lang="pug">
tr
  td(class="text-sm px-4")
    input(type="checkbox" :checked="isSelected" :class="lead-checkbox" @change="$emit('toggleSelect', lead.id)")
  td(class="text-sm")
    a(:href="'/leads/' + lead.id + '/edit'")
    h6(class="ms-3 my-auto d-flex align-items-center")
      i(class="fas fa-user text-secondary me-3")
      .word-wrapper(style="flex: 1;")
        | {{ lead.name }}
      .tags-wrapper
        span(class="badge bg-gradient-secondary mx-3" v-for="tag_name in lead.tag_list") {{ tag_name }}
  td
    span(class="badge bg-warning ms-3 my-auto mt-1" v-if="lead.status === 1") warm
    span(class="badge bg-danger ms-3 my-auto mt-1" v-else-if="lead.status === 2") hot
    span(class="badge bg-primary ms-3 my-auto mt-1" v-else-if="lead.status === 0") cold
    span(class="ms-3 my-auto mt-1" v-else)

  td(class="text-sm px-4") {{ lead.created_at }}
  td(class="text-sm px-4") {{ lead.phone }}
  td(class="text-sm px-4") {{ lead.home_phone }}
  td(class="text-sm px-4") {{ lead.business_phone }}

  td(class="text-sm text-center")
    a(v-if="lead.archived" href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Unarchive" @click.stop.prevent="unarchive")
      i(class="fas fa-undo text-secondary")

    a(v-else href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Archive" @click.stop.prevent="archive")
      i(class="fas fa-archive text-secondary")

    a(href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete" @click.stop.prevent="remove")
      i(class="fas fa-trash text-secondary")

</template>

<script>
import archiveData from '../../hooks/archiveDeleteData'

export default {
  props: {
    lead: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { archive, unarchive, remove } = archiveData('/api/leads/', props.lead.id, emit)

    return { archive, unarchive, remove }
  }
}
</script>
<style>
.word-wrapper {
  display: block;
  white-space: pre-wrap;
}

.tags-wrapper {
  display: flex;  /* Make the tag container flex for proper alignment */
  align-items: center;  /* Center tags vertically with the name */
}

</style>