import { createApp, defineComponent } from 'vue';
import axios from 'axios'; // If you use axios instead of jQuery for AJAX requests

document.addEventListener('DOMContentLoaded', function() { 
  if (document.getElementById("right_navigation")) {
    const MessagesGlobalCounter = defineComponent({
      name: 'MessagesGlobalCounter',
      data() {
        return {
          unread: messaging_unread_count,
          badge_seen: false
        };
      },
      created() {
        this.updateBadgeVisibility();
      },
      watch: {
        unread: 'updateBadgeVisibility'
      },
      methods: {
        updateBadgeVisibility() {
          this.badge_seen = (this.unread > 0);
        },
        // we need to request counter ourselves sometimes
        requestUpdatedCount() {
          axios.get('/dialogs/global_unread_count')
            .then(response => {
              this.unread = response.data.global_unread_count;
            })
            .catch(error => {
              console.error('Error fetching unread count:', error);
            });
        }
      }
    });

    // Create and mount the Vue app
    createApp(MessagesGlobalCounter).mount('#right_navigation');
  }
});
