document.addEventListener('turbo:load', () => {
  const sortDropdown = document.getElementById('sort-lead-dropdown');
  const queryButton = document.getElementById('query-lead-button');

  if (queryButton) {
    queryButton.addEventListener('click', function (event) {
      event.preventDefault();

      console.log("Query button clicked");  // Debugging

      if (sortDropdown) {
        const selectedSortValue = sortDropdown.value;
        console.log("Selected sort value:", selectedSortValue);  // Debugging

        const redirectUrl = `/api/leads/filter_sort?sort=${selectedSortValue}`;
        window.location.href = redirectUrl;
      } else {
        console.log("sortDropdown element not found.");  // Debugging
      }
    });
  } else {
    console.log("queryButton element not found.");  // Debugging
  }
  document.addEventListener('DOMContentLoaded', function () {
    var checkmark = document.getElementById('checkmark-lead-status');
    if (checkmark) {
        checkmark.style.display = 'none';
    }
  });
  $("#contact_status").on("change", function(event) {
    const selectedValue = $(this).val();

    setTimeout(function() {
      checkmark.style.display = 'none';
    }, 2000);
    if (selectedValue) {
        checkmark.style.display = 'inline-block';
    } else {
        checkmark.style.display = 'none';
    }
    var contactId = $("#contact_id").val();
    var statusValue = $(this).val();
    if (!statusValue && statusValue !== 0) {
        statusValue = "";
    }
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    $.ajax({
      url: "/leads/" + contactId + ".json",
      type: "PUT",
      data: {
        authenticity_token: csrf,
        id: contactId,
        contact: {
            status: statusValue
        }
      }
    });
  });

  $(".ofac_check.contacts").click(function(e) {
    e.preventDefault();
    
    var last_name = $("#contact_last_name").val();
    var first_name = $("#contact_first_name").val();
    var company_name = $("#contact_company_name").val();
    var id = $("#contact_id").val();
    var spinner = $(this).closest(".card-body").find(".spinner-ofac");
    spinner.show();

    $.ajax({
        url: "/leads/ofac_check.json",
        type: "POST",
        data: {
            authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
            contact: {
                id: id,
                last_name: last_name,
                first_name: first_name,
                company_name: company_name
            }
        },
        success: function(data, status, xhr) {
          setTimeout(function(){
            spinner.hide();
          }, 500);
          $("#ofac_status").text(data.status);
          $("#ofac_status").effect('highlight');
          $("#ofac_last_checked").text(data.last_ran);
          $("#contact_ofac_passed").val(data.ofac_passed);
          $("#contact_ofac_last_run").val(data.ofac_last_run_date);
        }
    });
  });
})
$(document).on('turbo:load', function() {
  const selectAllCheckbox = $('#select-all');
  const tableBody = $('#lead-table tbody');
  const loadingIcon = $('.loading-icon');
  let loading = false;

  // Function to refresh rowCheckboxes
  function refreshRowCheckboxes() {
    return $('.lead-checkbox');
  }

  // Function to update the selected lead count
  function updateSelectedLeadCount() {
    const checkedCheckboxes = refreshRowCheckboxes().filter(':checked').length;
    $('#selected-lead-count').text(checkedCheckboxes);
    toggleBulkActions(checkedCheckboxes); // Check the count and update visibility
  }

  // Function to toggle bulk actions visibility
  function toggleBulkActions(checkedCount) {
    if (checkedCount === 0) {
        $('.bulk-actions').hide(); // Hide bulk actions
        $('#selected-lead-count').hide(); // Hide selected leads text
        $('#selected-lead-text').hide();
    } else {
        $('.bulk-actions').show(); // Show bulk actions
        $('#selected-lead-count').show(); // Show selected leads text
        $('#selected-lead-text').show();
    }
  }

  // When "Select All" checkbox is checked or unchecked
  selectAllCheckbox.on('change', function() {
    const isChecked = $(this).is(':checked');
    refreshRowCheckboxes().prop('checked', isChecked);
    updateSelectAllState();
    updateSelectedLeadCount(); // Update count when selecting all
  });

  // When any row checkbox is checked or unchecked
  tableBody.on('change', '.lead-checkbox', function() {
    updateSelectAllState();
    updateSelectedLeadCount(); // Update count on individual checkbox change
  });

  // Function to update "Select All" checkbox based on row checkboxes state
  function updateSelectAllState() {
    const rowCheckboxes = refreshRowCheckboxes();
    const totalCheckboxes = rowCheckboxes.length;
    const checkedCheckboxes = rowCheckboxes.filter(':checked').length;

    if (checkedCheckboxes === totalCheckboxes) {
        selectAllCheckbox.prop({ indeterminate: false, checked: true }).removeClass('indeterminate');
    } else if (checkedCheckboxes === 0) {
        selectAllCheckbox.prop({ indeterminate: false, checked: false }).removeClass('indeterminate');
    } else {
        selectAllCheckbox.prop({ indeterminate: true, checked: false }).addClass('indeterminate');
    }
  }

  const nextPageUrl = () => {
    const currentUrl = new URL(window.location.href);
    const currentPage = parseInt(currentUrl.searchParams.get("page")) || 1;
    currentUrl.searchParams.set("page", currentPage + 1);
    console.log('Next page URL:', currentUrl.toString()); // Debugging line
    return currentUrl.toString();
  };

  // Scroll event on the wrapper
  $('.table-wrapper').on('scroll', function() {
    if (!loading && $(this).scrollTop() + $(this).outerHeight() >= this.scrollHeight - 10) {
      loading = true;
      loadingIcon.show();

      $.get(nextPageUrl(), null, function(data) {
          tableBody.append(data);
          loading = false;
          loadingIcon.hide();
          // Re-assign rowCheckboxes after new data is added
          updateSelectAllState(); // Update state after new data
          updateSelectedLeadCount(); // Update selected lead count after new data
      }).fail(function() {
          loading = false;
          loadingIcon.hide(); // Hide loading on error
      });
    }
  });

  // Initial call to toggle bulk actions based on the initial state
  updateSelectedLeadCount();
  });

  $(document).on('click', '#cstm-delete-btn', function() {
    const selectedIds = getSelectedLeadIds();
    if (selectedIds.length === 0) {
        alert('No leads selected for deletion.');
        return;
    }
    
    $.ajax({
      url: '/api/leads/lead_bulk_destroy', // Update this path if needed
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') // Add CSRF token here
      },
      data: JSON.stringify({ lead_ids: selectedIds }),
      success: function(response) {
        location.reload(); // Refresh the page
      },
      error: function(xhr) {
        alert('Error deleting leads: ' + xhr.responseText);
      }
    });
  });

  $(document).on('click', '#cstm-archive-btn', function() {
    const selectedIds = getSelectedLeadIds();
    if (selectedIds.length === 0) {
      alert('No leads selected for archiving.');
      return;
    }
    
    $.ajax({
      url: '/api/leads/lead_bulk_archive', // Make sure this URL matches your route
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') // Add CSRF token
      },
      data: JSON.stringify({ lead_ids: selectedIds }),
      success: function(response) {
        location.reload(); // Refresh the page
      },
      error: function(xhr) {
        alert('Error deleting leads: ' + xhr.responseText);
      }
    });
  });

  // Function to get selected lead IDs
  function getSelectedLeadIds() {
    return $('.lead-checkbox:checked').map(function() {
        return parseInt(this.value, 10); // Convert the value to an integer
    }).get();
  }

$(window).on('load', function() {
  // Delegated event to handle dynamically added checkboxes
  $(document).on('change', 'input[type="checkbox"]', function() {
    // 'this' refers to the checkbox that was changed
    const selectedLeadCount = document.getElementById('selected-lead-count');
    const bulkActions = $('#bulk-actions');
    const count = parseInt(selectedLeadCount.textContent) || 0;

    if(count > 0){
      bulkActions.show();
      $('#selected-lead-text').show();
      $('#selected-lead-count').show(); // Show selected leads text
    } else {
      bulkActions.hide(); // Hide bulk actions
      $('#selected-lead-text').hide();
      $('#selected-lead-count').hide(); // Hide selected leads text
    }
  });
});
